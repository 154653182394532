import React from "react"
import { Box } from '@mui/material';
import CodeTypingExperiment from '../../components/experiments/code-typing/code-typing-experiment';

export default () => {
    return (
        <Box mx={2}>
            <CodeTypingExperiment uuid="07f6f138-6fda-4526-8948-7a0270803c56" />
        </Box>
    );
};